import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GET_SYSTEM_PRODUCTS, GET_SYSTEM_WARRANTY_FLOW } from '../../../store/types'

import Table from "../../../common/table"
import { get } from '../../../store/actions/crud/get'
import Add from "./add"
import { deleteDoc } from "../../../store/actions/crud/delete"

const WarrantyFlow = () => {
    const dispatch = useDispatch()
    const { warrantyFlow, data: products, isLoading } = useSelector(state => state.systemProducts)

    useEffect(() => {

        dispatch(get({

            body: {
                _model: "WarrantyFlow"
            },

            dispatchDetail: (response) => ({
                type: GET_SYSTEM_WARRANTY_FLOW,
                payload: { warrantyFlow: response.data.doc }
            })

        }))

        dispatch(get(
            {
                body: {
                    _model: "Product",
                    attributes: {
                        name: 1,
                        serialNumber: 1,
                        modelNumber: 1,
                        manufacturerId: 1,
                        description: 1,
                    }
                },

                dispatchDetail: (response) => ({
                    type: GET_SYSTEM_PRODUCTS,
                    payload: { products: response.data.doc }
                }),
            }
        ))

    }, [])

    const deleteFunction = (obj) => {
        dispatch(deleteDoc(
            {
                body: {
                    _model: "WarrantyFlow",
                    params: {
                        _id: obj._id
                    },
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "WarrantyFlow"
                        },

                        dispatchDetail: (response) => ({
                            type: GET_SYSTEM_WARRANTY_FLOW,
                            payload: { warrantyFlow: response.data.doc }
                        })
                    }

                )),




            }
        ))
    }

    if (isLoading || !products) {
        return <p>Loading...</p>
    }

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Warranty Flows"} data={warrantyFlow} addDelete={true} deleteFunction={deleteFunction} />
                <Add products={products} />
            </div>
        </div>
    )
}

export default WarrantyFlow