import { useEffect } from 'react';
import Table from '../../../common/table';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getProducts, getProductTypes, getManufacturers, addProduct, deleteProduct } from '../../../store/actions/admin/products';
import { deleteDoc } from '../../../store/actions/crud/delete'
import { get } from '../../../store/actions/crud/get'
import { GET_SYSTEM_PRODUCTS } from '../../../store/types';

import AddProduct from './addProduct';
import Text from '../../../common/text';

const Products = () => {
    const dispatch = useDispatch()
    const { admin } = useSelector(state => state.auth)
    const { data, types, manufacturers, isLoading } = useSelector(state => state.systemProducts)

    useEffect(() => {
        dispatch(getProducts())
        dispatch(getProductTypes())
        dispatch(getManufacturers())
    }, [])

    if (isLoading) {
        return <p>Loading...</p>
    }

    const deleteFunction = (obj) => {

        dispatch(deleteDoc(
            {
                body: {
                    _model: "Product",
                    params: {
                        _id: obj._id
                    },
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "Product",
                            attributes: {
                                name: 1,
                                serialNumber: 1,
                                modelNumber: 1,
                                manufacturerId: 1,
                                description: 1,
                            }
                        },

                        dispatchDetail: (response) => ({
                            type: GET_SYSTEM_PRODUCTS,
                            payload: { products: response.data.doc }
                        }),
                    }
                ))


            }
        ))


    }

    console.log("DATA IS ======", data)

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"System Products"} data={data} addDelete={true} deleteFunction={deleteFunction} />

                {manufacturers.length > 0 ?
                    <AddProduct types={types} manufacturers={manufacturers} addProduct={addProduct} /> :
                    <Text type={5}>You need to add manufacturers before adding products.</Text>
                }
            </div>
        </div>
    )
}

export default Products
