import React from 'react'
import Button from '../../common/button'
import Text from '../../common/text'
import Login from '../auth/login'
import Register from '../auth/register'

const Unauthorized = () => {
    return (
        <div style={{
            // border: '1px solid blue',
            borderRadius: '0px 0px 16px 16px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
            padding: '.5rem 1.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'fixed',
            top: 0,
            backgroundColor: '#fff',
            // marginBottom: '60px',

        }}>
            <img src="images/calimarLogo.png" width={50} height={50} />

            <Text type={2}>Calimar Warranty System</Text>

            <div style={{
                display: 'flex'
            }}>
                <div style={{
                    margin: '0px 8px'
                }}>
                    <Login />
                </div>
                <div>
                    <Register />
                </div>
            </div>
        </div >
    )


}

export default Unauthorized