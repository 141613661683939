import { GET_SYSTEM_PRODUCTS, SYSTEM_PRODUCTS_LOADING, GET_SYSTEM_PRODUCT_TYPES, GET_SYSTEM_PRODUCT_MANUFACTURERS, GET_SYSTEM_PRODUCT_DEALERS, GET_SYSTEM_WARRANTY_FLOW } from '../types'

const initialState = {
    data: [],
    types: [],
    manufacturers: [],
    dealers: [],
    warrantyFlow: [],
    isLoading: false
}

const adminProductsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_SYSTEM_PRODUCTS:
            return {
                ...state,
                data: action.payload.products,
                isLoading: false
            }

        case GET_SYSTEM_PRODUCT_TYPES:
            return {
                ...state,
                types: action.payload.productTypes,
                isLoading: false
            }

        case GET_SYSTEM_PRODUCT_MANUFACTURERS:
            return {
                ...state,
                manufacturers: action.payload.manufacturers,
                isLoading: false
            }

        case GET_SYSTEM_PRODUCT_DEALERS:
            return {
                ...state,
                dealers: action.payload.dealers,
                isLoading: false
            }

        case GET_SYSTEM_WARRANTY_FLOW:
            return {
                ...state,
                warrantyFlow: action.payload.warrantyFlow,
                isLoading: false
            }


        case SYSTEM_PRODUCTS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}

export default adminProductsReducer