import { useNavigate } from "react-router-dom"
import Button from "../../../../common/button"
import Text from "../../../../common/text"

const ClaimComplete = () => {
    const navigate = useNavigate()


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 73px)'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                <Text type={1}>You have successfully submitted your warranty claim</Text>
                <Text type={2} style={{ margin: '8px 0px' }}>Someone will reach out to you within 24 hours with a resolution</Text>
                <Text type={2}>If you have any additional questions, just keep them to yourself.</Text>

                <Button name={"Back to home"} style={{ margin: '32px 0px' }} onClick={() => navigate('/')} />
            </div>


            {/* <div style={{
                borderRadius: '16px 16px 0px 0px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>

                <Button name={"Home"} />

            </div> */}
        </div >
    )

}

export default ClaimComplete