import Pools from './pools'
import Products from './products'
import WarrantyClaim from './warrantyClaim'

const UserDashboard = () => {
    return (
        <div>
            <Pools />
            <Products />
            <WarrantyClaim />

        </div>
    )
}

export default UserDashboard