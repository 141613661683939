import Checkbox from "../../../../common/form/checkbox"
import Input from "../../../../common/form/input"

const Answers = ({ answers, answerType, setSelectedAnswer, selectedAnswer }) => {

    switch (answerType) {
        case "radio":
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '16px 0px'
                }}>
                    {answers.map((item, index) => (
                        <label>
                            {item.title}
                            <input style={{
                                marginLeft: '8px'
                            }} type="radio" onChange={() => setSelectedAnswer(item)} />
                        </label>

                    ))}
                </div>
            )

        case "checkbox":
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '16px 8px'
                }}>
                    {answers.map((item, index) => (
                        <Checkbox key={index} setSelectedAnswer={setSelectedAnswer} selectedAnswer={selectedAnswer} item={item} />
                    ))}
                </div>
            )

        case "input":
            return (
                <Input type={"text"} onChange={e => setSelectedAnswer(e.target.value)} value={selectedAnswer} />
            )

        case "input-number":
            return (
                <Input type={"number"} onChange={e => setSelectedAnswer(e.target.value)} value={selectedAnswer} />

            )

        case "textArea":
            return (
                <Input type={"textArea"} onChange={e => setSelectedAnswer(e.target.value)} value={selectedAnswer} />
            )


        default:
            break;
    }



}

export default Answers