import { GET_SYSTEM_PRODUCT_MANUFACTURERS, SYSTEM_PRODUCTS_LOADING, GET_NOTIFICATIONS } from "../../../types"

import axios from 'axios'

export const getManufacturers = () => async (dispatch) => {
    dispatch({ type: SYSTEM_PRODUCTS_LOADING })

    const body = {
        _model: "Manufacturer",
        attributes: {
            name: 1,
            slug: 1,
        }
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/get`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)

    dispatch({
        type: GET_SYSTEM_PRODUCT_MANUFACTURERS,
        payload: { manufacturers: response.data.doc }
    })
}