import { useEffect } from 'react';
import Table from '../../../common/table';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { deleteDoc } from '../../../store/actions/crud/delete';
import { getAdminProductTypes } from '../../../store/actions/admin/products/getAdminProductTypes';
import { getProductTypes } from '../../../store/actions/admin/products';


// COMPONENTS
import AddProductType from './addProductType';

const ProductTypes = () => {
    const dispatch = useDispatch()
    const { admin } = useSelector(state => state.auth)
    const { types, isLoading } = useSelector(state => state.systemProducts)
    const data = [] // testing only

    useEffect(() => {
        dispatch(getProductTypes())
    }, [])

    if (isLoading) {
        return <p>Loading...</p>
    }

    const deleteFunction = (obj) => {
        dispatch(deleteDoc(
            {
                body: {
                    _model: "ProductType",
                    params: {
                        _id: obj._id
                    },
                },
                callback: () => dispatch(getAdminProductTypes())
            }
        ))
    }

    return (
        <div className='p-4 text-white' >
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Product Types"} data={types} addDelete={true} deleteFunction={deleteFunction} />
                <AddProductType />
            </div>
        </div>
    )
}

export default ProductTypes
