const Text = ({ type, children, style }) => {
    let styleType = {
        color: 'red',
        lineHeight: '100%',
        // textAlign: 'left'
    }

    if (type === 1) {
        styleType.color = 'black'
        styleType.fontSize = '3rem'
    }

    if (type === 2) {
        styleType.color = 'black'
        styleType.fontSize = '1.5rem'
    }

    if (type === 3) {
        styleType.color = 'black'
        styleType.fontSize = '1.05rem'
        // styleType.fontWeight = 800
    }

    if (type === 4) {
        styleType.color = 'black'
        styleType.fontSize = '1.05rem'
        styleType.lineHeight = '150%'
        // styleType.fontWeight = 400
    }

    if (type === 5) {
        styleType.color = '#fff'
        styleType.fontSize = '1.05rem'
        styleType.lineHeight = '150%'
        // styleType.fontWeight = 400
    }

    return (
        <span style={{ ...styleType, ...style }}>{children}</span>
    )
}

export default Text