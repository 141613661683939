import { GET_POOLS, ADD_POOLS, POOLS_LOADING } from '../types'

const initialState = {
    data: [],
    isLoading: false
}

const poolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POOLS:
            return {
                ...state,
                data: action.payload.pools,
                isLoading: false
            }
        case ADD_POOLS:
            return {
                ...state,
                data: action.payload.pools
            }
        case POOLS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}

export default poolsReducer