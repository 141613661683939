import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './auth'
import poolsReducer from './pools'
import productsReducer from './products'
import notificationReducer from './notification'
import adminProductsReducer from './systemProducts'
import warrantyClaimsReducer from './warrantyClaims'
import systemReducer from './system'

const RootReducer = combineReducers({
    auth: authReducer,
    pools: poolsReducer,
    products: productsReducer,
    notification: notificationReducer,
    systemProducts: adminProductsReducer,
    warrantyClaims: warrantyClaimsReducer,
    system: systemReducer,

})

export default RootReducer 