import { GET_PRODUCTS, ADD_PRODUCTS, PRODUCTS_LOADING } from '../types'

const initialState = {
    data: [],
    isLoading: false
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_PRODUCTS:
            return {
                ...state,
                data: action.payload.products,
                isLoading: false
            }
        case ADD_PRODUCTS:
            return {
                ...state,
                data: action.payload.products
            }
        case PRODUCTS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}

export default productsReducer