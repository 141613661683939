import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clearError } from '../store/actions/notification/clearError'
import Text from './text'


const Notification = () => {
    const dispatch = useDispatch()
    const { msg, status, type } = useSelector(state => state.notification)

    const [display, setDisplay] = useState('hidden')
    const [color, setColor] = useState('')

    useEffect(() => {

        if (msg || status) {
            if (type === "error") setColor("#f52525")
            if (type === "success") setColor("#3b82f6")

            setDisplay('absolute')
            setTimeout(() => {
                setDisplay('hidden')
                dispatch(clearError())
            }, 2 * 1000);
        }

    }, [msg])


    console.log(display)

    return (
        <div className={`${display}`} style={{
            // border: '5px solid green',
            width: '100%',
            zIndex: "100 !important",
            display: 'flex',
            justifyContent: 'center',
            top: 25,

        }}>

            <div className={`${display}`}
                style={{
                    zIndex: 50,
                    backgroundColor: color,
                    textAlign: 'center',
                    maxWidth: '500px',
                    padding: '2rem',
                    borderRadius: '8px',
                }}

            >
                {/* <h1 className='text-white'>Error: {status}</h1> */}
                {/* <p className='text-white'>{msg}</p> */}
                <Text type={5}>{msg}</Text>
            </div>
        </div>
    )
}

export default Notification