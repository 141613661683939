import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../common/button';

import { useDispatch } from 'react-redux';
import { register } from '../../store/actions/auth/register';
import { GET_NOTIFICATIONS } from '../../store/types';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function Register() {
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = () => {
        var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!filter.test(email)) {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: 'Please provide a valid email address', type: "error" }
            })
            // alert('Please provide a valid email address');
            return false;
        }

        dispatch(register(
            {
                email,
                password,
                lastName,
                firstName

            }))
    }

    // const handleEmailValidation = () => {
    //     var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (!filter.test(email)) {
    //         alert('Please provide a valid email address');
    //         return false;
    //     }
    // }

    // useEffect(() => {
    //     var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (!filter.test(email)) {
    //         alert('Please provide a valid email address');
    //         return false;
    //     }
    // }, [email])

    return (
        <div>
            <Button name={"Register"} onClick={openModal} />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Register Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>Register</h2>
                <form className='flex flex-col p-4 '>
                    <label className='p-1 flex flex-col'>
                        First Name
                        <input type="email" onChange={e => setfirstName(e.target.value)} />
                    </label>
                    <label className='p-1 flex flex-col'>
                        Last Name
                        <input type="email" onChange={e => setlastName(e.target.value)} />
                    </label>
                    <label className='p-1 flex flex-col'>
                        Email Address
                        <input type="email" onChange={e => setEmail(e.target.value)} />
                    </label>
                    <label className='p-1 flex flex-col'>
                        Password
                        <input type="password" onChange={e => setPassword(e.target.value)} />
                    </label>
                </form>
                <div className='flex justify-between p-2'>
                    <Button name={"Cancel"} onClick={closeModal} />
                    <Button name={"Submit"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default Register