import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../button';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const ConfirmDelete = ({ object, deleteFunction }) => {


    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const confirmDelete = () => {
        deleteFunction(object)
        closeModal()

    }

    return (
        <div>
            <div className="p-4" >
                <Button icon={'Trash'} type={2} name={"Delete"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="confirm delete"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>Are you sure you want to delete this?</h2>

                <div className='flex justify-between p-2'>
                    <Button type={3} name={"Cancel"} onClick={closeModal} />
                    <Button type={2} icon={'Trash'} name={"Delete"} onClick={confirmDelete} />
                </div>
            </Modal>
        </div>
    );

}

export default ConfirmDelete