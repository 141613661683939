import { useState } from "react"
import Text from "../../common/text"
import Dealers from "./dealers"
import Manufacturers from "./manufacturers"
import ProductTypes from "./productTypes/productTypes"
import Products from "./systemProducts/products"
import Users from "./users/users"
import WarrantyClaim from "./warrantyClaims"
import WarrantyFlow from "./warrantyFlow"

const AdminDashboard = () => {
    const [activeModule, setActiveModule] = useState('warrantyClaims')

    let buttonStyle = ({ module }) => ({
        padding: '.5rem',
        margin: '5px 0px',
        borderRadius: '6px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, .5)',
        width: '90%',
        cursor: 'pointer',
        backgroundColor: (module === activeModule ? '#eee' : '#fff'),
        boxShadow: (module === activeModule ? '0px 0px 6px rgba(0, 0, 0, .5)' : '0px 0px 2px rgba(0, 0, 0, .5)'),

        // Hover effect
        '&:hover': { boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)', },
    })

    const displayModule = () => {
        switch (activeModule) {
            case 'systemProducts':
                return <Products />

            case 'productTypes':
                return <ProductTypes />

            case 'manufacturers':
                return <Manufacturers />

            case 'dealers':
                return <Dealers />

            case 'warrantyFlows':
                return <WarrantyFlow />

            case 'warrantyClaims':
                return <WarrantyClaim />

            case 'users':
                return <Users />

            default:
                break;
        }
    }

    return (
        <div style={{
            display: 'flex',
            marginTop: '60px'
        }}>
            <div style={{
                // borderRight: '1px red solid',
                boxShadow: '0px 3px 10px rgba(0, 0, 0, .5)',
                width: '200px',
                height: 'calc(100vh - 58px)',
                position: 'fixed',
                // flexShrink: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: 'center'
                backgroundColor: '#fff'
            }}>
                <div style={buttonStyle({ module: 'systemProducts' })} onClick={() => setActiveModule('systemProducts')}>
                    <Text type={3}>System Products</Text>
                </div>

                {/* <div style={buttonStyle({ module: 'productTypes' })} onClick={() => setActiveModule('productTypes')}>
                    <Text type={3}>Product Types</Text>
                </div> */}

                <div style={buttonStyle({ module: 'manufacturers' })} onClick={() => setActiveModule('manufacturers')}>
                    <Text type={3}>Manufacturers</Text>
                </div>

                <div style={buttonStyle({ module: 'dealers' })} onClick={() => setActiveModule('dealers')}>
                    <Text type={3}>Dealers</Text>
                </div>

                <div style={buttonStyle({ module: 'warrantyFlows' })} onClick={() => setActiveModule('warrantyFlows')}>
                    <Text type={3}>Warranty Flows</Text>
                </div>

                <div style={buttonStyle({ module: 'warrantyClaims' })} onClick={() => setActiveModule('warrantyClaims')}>
                    <Text type={3}>Warranty Claims</Text>
                </div>

                <div style={buttonStyle({ module: 'users' })} onClick={() => setActiveModule('users')}>
                    <Text type={3}>Users</Text>
                </div>

            </div>
            <div style={{
                marginLeft: '200px',
                // flexGrow: 1,
                width: '100%'

            }}>

                {displayModule()}

            </div>
        </div>
    )
}

export default AdminDashboard