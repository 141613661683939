import { LOGIN_ADMIN, GET_NOTIFICATIONS } from "../../types"
import axios from 'axios'

export const adminLogin = (params) => async (dispatch) => {
    const body = {
        _model: "Admin",
        params
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login-admin`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)

    dispatch({
        type: LOGIN_ADMIN,
        payload: { token: response.data.token, admin: response.data.account }

    })

    dispatch({
        type: GET_NOTIFICATIONS,
        payload: { msg: `Welcome Back ${response.data.account.firstName ? response.data.account.firstName : ""}`, type: "success" }
    })

    window.location = '/'
}