// AUTH
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_ADMIN = 'LOGIN_ADMIN'
export const SIGN_OUT = 'SIGN_OUT'

// POOLS
export const GET_POOLS = 'GET_POOLS'
export const ADD_POOLS = 'ADD_POOLS'
export const POOLS_LOADING = 'POOLS_LOADING'

// PRODUCTS
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const ADD_PRODUCTS = 'ADD_PRODUCTS'
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING'

// ERRORS
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

// ADMIN
export const GET_SYSTEM_PRODUCTS = 'GET_SYSTEM_PRODUCTS'
export const GET_SYSTEM_PRODUCT_TYPES = 'GET_SYSTEM_PRODUCT_TYPES'
export const GET_SYSTEM_PRODUCT_MANUFACTURERS = 'GET_SYSTEM_PRODUCT_MANUFACTURERS'
export const GET_SYSTEM_PRODUCT_DEALERS = 'GET_SYSTEM_PRODUCT_DEALERS'
export const GET_SYSTEM_WARRANTY_FLOW = 'GET_SYSTEM_WARRANTY_FLOW'
export const SYSTEM_PRODUCTS_LOADING = 'SYSTEM_PRODUCTS_LOADING'

// WARRANTY CLAIM
export const GET_WARRANTY_CLAIMS = 'GET_WARRANTY_CLAIMS'
export const GET_WARRANTY_USER = 'GET_WARRANTY_USER'
export const GET_WARRANTY_DETAIL = 'GET_WARRANTY_DETAIL'
export const WARRANTY_CLAIMS_LOADING = 'WARRANTY_CLAIMS_LOADING'

// SYSTEM
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS'
export const SYSTEM_LOADING = 'SYSTEM_LOADING'