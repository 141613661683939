import { useEffect } from 'react';
import Table from '../../../common/table';
import AddProduct from './addProduct';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { get } from '../../../store/actions/crud/get'
import { GET_SYSTEM_PRODUCTS, GET_SYSTEM_PRODUCT_DEALERS, GET_PRODUCTS, GET_SYSTEM_PRODUCT_TYPES } from '../../../store/types';
import { deleteDoc } from '../../../store/actions/crud/delete';


const Products = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { data, isLoading } = useSelector(state => state.products)
    const { data: products, dealers, types } = useSelector(state => state.systemProducts)

    console.log("hello1", data)


    useEffect(() => {
        if (user) {
            dispatch(get({
                body: {
                    _model: "UserProduct",
                    params: {
                        userId: user._id
                    },
                    attributes: {
                        // poolId: 1,
                        // userId: 1,
                        // userPool: 1,
                        // dealerId: 1,
                        productDetail: 1,
                        orderNumber: 1,
                        serialNumber: 1,
                        purchaseDate: 1,
                        installDate: 1
                        // userId: 1,
                    }
                },
                dispatchDetail: (response) => (
                    dispatch({
                        type: GET_PRODUCTS,
                        payload: { products: response.data.doc }
                    })
                )
            }))
        }

        dispatch(get({
            body: {
                _model: "Product"
            },
            dispatchDetail: (response) => (
                dispatch({
                    type: GET_SYSTEM_PRODUCTS,
                    payload: { products: response.data.doc }
                })
            )
        }))

        dispatch(get(
            {
                body: {
                    _model: "ProductType"
                },
                dispatchDetail: (response) => ({
                    type: GET_SYSTEM_PRODUCT_TYPES,
                    payload: { productTypes: response.data.doc }
                }),
            }
        ))

        dispatch(get({
            body: {
                _model: "Dealer"
            },
            dispatchDetail: (response) => (
                dispatch({
                    type: GET_SYSTEM_PRODUCT_DEALERS,
                    payload: { dealers: response.data.doc }
                })
            )
        }))

    }, [])


    const deleteFunction = (obj) => {
        dispatch(deleteDoc(
            {
                body: {
                    _model: "UserProduct",
                    params: {
                        _id: obj._id
                    },
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "UserProduct"
                        },

                        dispatchDetail: (response) => ({
                            type: GET_PRODUCTS,
                            payload: { products: response.data.doc }
                        }),
                    }

                )),
            }
        ))
    }


    if (isLoading) {
        return <p>loading</p>
    }

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Products"} data={data} addDelete={true} deleteFunction={deleteFunction} />
                <AddProduct userId={user._id} types={types || []} dealers={dealers || []} products={products || []} />
            </div>
        </div>
    )
}

export default Products
