import { GET_WARRANTY_CLAIMS, GET_WARRANTY_USER, GET_WARRANTY_DETAIL, WARRANTY_CLAIMS_LOADING } from '../types'

const initialState = {
    data: [],
    warrantyUser: null,
    warrantyDetail: null,
    isLoading: false
}

const warrantyClaimsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_WARRANTY_CLAIMS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false
            }

        case GET_WARRANTY_USER:
            return {
                ...state,
                warrantyUser: action.payload.data,
                isLoading: false
            }

        case GET_WARRANTY_DETAIL:
            return {
                ...state,
                warrantyDetail: { ...state.warrantyDetail, ...action.payload.data },
                isLoading: false
            }

        case WARRANTY_CLAIMS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}

export default warrantyClaimsReducer