import { LOGIN_USER, GET_NOTIFICATIONS } from "../../types"

import axios from 'axios'

export const register = (params) => async (dispatch) => {

    const body = {
        _model: "User",
        params
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/new-user`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)

    dispatch({
        type: LOGIN_USER,
        payload: { token: response.data.token, user: response.data.account }
    })
}