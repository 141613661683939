const Select = ({ options, label, onChange }) => {

    // console.log(options)

    let style = {
        border: '1px solid rgba(0, 0, 0, .25)',
        borderRadius: '8px',
        width: '80%',
        height: '50px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, .5)',
        margin: '24px 0px',
        padding: '.5rem'
    }

    return (
        <select style={style} onChange={onChange}>
            <option>{label}</option>
            {options.map((item, index) => (
                <option value={JSON.stringify(item)}>{item.productDetail.name} - Serial number: {item.serialNumber} - From order: {item.orderNumber}</option>
            ))}
        </select>
    )

}

export default Select