import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../common/button';

import { useDispatch } from 'react-redux';
import { login } from '../../store/actions/auth/login';
import { useNavigate } from 'react-router-dom';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function Login() {
    // const navigate = useNavigate()
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = async () => {
        await dispatch(login(email, password))
        // navigate('/')
    }

    return (
        <div>
            <Button name={"Login"} onClick={openModal} />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Login Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>Login</h2>
                <form className='flex flex-col p-4 '>
                    <label className='p-1 flex flex-col'>
                        Email Address
                        <input type="email" onChange={e => setEmail(e.target.value)} />
                    </label>
                    <label className='p-1 flex flex-col'>
                        Password
                        <input type="password" onChange={e => setPassword(e.target.value)} />
                    </label>
                </form>
                <div className='flex justify-between p-2'>
                    <Button name={"Cancel"} onClick={closeModal} />
                    <Button name={"Login"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default Login