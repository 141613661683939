import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import Button from '../../../common/button'
import { create } from '../../../store/actions/crud/create'
import { useDispatch } from 'react-redux'
import { get } from '../../../store/actions/crud/get'
import { GET_SYSTEM_WARRANTY_FLOW } from '../../../store/types'
import Text from '../../../common/text'
import { TiTimes } from "react-icons/ti";

const CreateFlow = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { name, selectedProducts } = location.state

    const dispatch = useDispatch()

    const [flow, setFlow] = useState([])
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')

    const [answerType, setAnswerType] = useState('')
    const [answer, setAnswer] = useState('')
    const [answers, setAnswers] = useState([])


    const addToFlow = () => {

        // Create root flow object
        const newFlow = {
            // id: Math.random().toString(16).slice(2),
            title,
            subtitle,
            answerType,
            answers,
        }
        setFlow([...flow, newFlow])

        // Reset state
        setTitle('')
        setSubtitle('')
        setAnswers([])
        setAnswerType('')

        return

    }

    const handleSaveFlow = async () => {
        if (!flow) return alert("Error: no dealer details to submit")

        let newWarrantyFlow = {
            name,
            products: selectedProducts,
            flow
        }


        await dispatch(create(
            {
                body: {
                    _model: "WarrantyFlow",
                    params: newWarrantyFlow
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "WarrantyFlow"
                        },
                        dispatchDetail: (response) => ({
                            type: GET_SYSTEM_WARRANTY_FLOW,
                            payload: { warrantyFlow: response.data.doc }
                        })
                    }
                ))
            }
        ))

        navigate('/')

    }

    const handleAddToAnswers = (e) => {
        e.preventDefault()
        console.log('handling add to answers array', answers)
        setAnswers([...answers, answer])
        setAnswer('')

    }

    const deleteAnswer = (item) => {
        console.log('delete answer clicked', item, answers)
        for (let i = 0; i < answers.length; i++) {
            if (answers[i] === item) {
                console.log('found answer to delete ', i)
                answers.splice(i, 1)
            }
        }
        setAnswers([...answers])
        console.log(answers)
    }

    if (!name || !selectedProducts) {
        return <p>Error</p>
    }

    return (
        <div style={{
            // border: '1px solid red',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 60px)',
            marginTop: '60px'
        }}>
            <div>

                <div>
                    <p>New warranty flow: {name}</p>
                    <p>Selected Products:</p>
                    {selectedProducts.map((item, index) => (
                        <p>{item.name}</p>
                    ))}

                    <form className='flex flex-col p-4 '>

                        <label className='p-1 flex flex-col'>
                            <p className="font-semibold">Title:</p>
                            <input autoFocus type="text" onChange={e => setTitle(e.target.value)} value={title} />
                        </label>

                        <label className='p-1 flex flex-col'>
                            <p className="font-semibold">Subtitle:</p>
                            <input type="text" onChange={e => setSubtitle(e.target.value)} value={subtitle} />
                        </label>

                        <label className='p-1 flex flex-col'>
                            <p className="font-semibold">Answer Type</p>
                            <select onChange={e => setAnswerType(e.target.value)} value={answerType}>
                                <option>Select Answer Type</option>
                                <option value={'input'}>Text Input</option>
                                <option value={'input-number'}>Number Input</option>
                                <option value={'radio'}>Radio Button</option>
                                <option value={'checkbox'}>Checkbox</option>
                                <option value={'select'}>Drop Down</option>
                                <option value={'textArea'}>Text Area</option>

                            </select>
                        </label>

                        {['radio', 'checkbox', 'select'].includes(answerType) ?
                            <>
                                <label className='p-1 flex flex-col'>
                                    <p className="font-semibold">Answers</p>
                                    <div>
                                        <input type='text' onChange={e => setAnswer(e.target.value)} />
                                        <Button name={'Add Answer'} onClick={e => handleAddToAnswers(e)} />
                                    </div>
                                </label>

                                <div style={{
                                    display: 'flex'
                                }}>

                                    {answers.map((item, index) => (
                                        <div style={{
                                            padding: '.5rem',
                                            margin: '5px 5px',
                                            borderRadius: '6px',
                                            boxShadow: '0px 0px 2px rgba(0, 0, 0, .5)',
                                            // width: '90%',
                                            flexShrink: 1,
                                            cursor: 'pointer',
                                        }} >
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <TiTimes onClick={() => deleteAnswer(item)} />
                                                <span>{item}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                            : null}



                    </form>
                    <Button name={'Add to flow'} onClick={addToFlow} />
                </div>

                {/* <p>{JSON.stringify(flow)}</p> */}
                <div style={{
                    padding: '1rem'
                }}>
                    <Text type={2}>Warranty Flow</Text>
                    {flow.map((item, index) => (
                        <div style={{
                            margin: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            // borderBottom: '1px solid #767676'
                        }}>
                            <Text type={4}>{item.title}</Text>
                            <Text type={4}>{item.subtitle || null}</Text>
                            <Text type={4}>{item.answerType}</Text>
                            {item.answers.map((answer, index) => (
                                <Text type={4}> - {answer}</Text>
                            ))}
                        </div>
                    ))}
                </div>

            </div>
            <div style={{
                // border: '1px solid blue',
                borderRadius: '16px 16px 0px 0px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button name={'Save Flow'} onClick={handleSaveFlow} />
            </div>

        </div>
    )
}

export default CreateFlow

// •	Serial Number
// •	Description of the issue
// •	Error codes being displayed
// •	Current water test from a LOCAL POOL STORE
// •	Each diagnostic display 1 or 2 minutes after system being turned on(BEFORE the error lights come on) for the most accurate reading. ** THESE READINGS NEED TO BE WHILE THE SYSTEM IS ON ** (Picture / video would be ideal)  
// •	Desired Chlorine Output
// •	Pump info - Brand, model, horsepower, speeds
// •	How many hours a day is the pump ran ?
// •	Actual pool water temperature