import { GET_NOTIFICATIONS } from "../../types"

import axios from 'axios'

export const update = ({ body, callback, dispatchDetail }) => async (dispatch) => {
    console.log('update action', { body, callback, dispatchDetail })
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/update`, body).catch(
        err => {
            console.log("====error", err)


            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    // console.log("GET RESPONSE", response)
    // callback && callback()
    dispatchDetail && dispatch(dispatchDetail(response))
}