import Text from "../../../common/text"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { GET_WARRANTY_DETAIL, GET_WARRANTY_USER } from "../../../store/types"
import { get } from "../../../store/actions/crud/get"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../common/button"
import moment from "moment"
import { checkErrorCodes, getCellEfficiency } from "./utilities"
import { TiEdit, TiTimes, TiTick } from "react-icons/ti";
import { update } from "../../../store/actions/crud/update"


const ClaimDetail = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { claim } = location.state

    const dispatch = useDispatch()
    const { warrantyUser, warrantyDetail } = useSelector(state => state.warrantyClaims)

    const [claimDetail, setClaimDetail] = useState(null)
    const [editStatus, setEditStatus] = useState(false)

    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "User",
                    params: {
                        _id: claim.userId
                    }
                },

                dispatchDetail: (response) => ({
                    type: GET_WARRANTY_USER,
                    payload: { data: response.data.doc[0] }
                }),
            }
        ))
    }, [])

    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "Dealer",
                    params: {
                        _id: claim.dealerId
                    }
                },

                dispatchDetail: (response) => ({
                    type: GET_WARRANTY_DETAIL,
                    payload: { data: { dealer: response.data.doc[0] } }
                }),
            }
        ))
    }, [])

    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "WarrantyClaim",
                    params: {
                        _id: claim._id
                    }
                },

                dispatchDetail: (response) => ({
                    type: GET_WARRANTY_DETAIL,
                    payload: { data: { claim: response.data.doc[0] } }
                }),
            }
        ))
    }, [])

    useEffect(() => {
        if (warrantyDetail && warrantyDetail.claim) {
            setClaimDetail(warrantyDetail.claim)
        }
    }, [warrantyDetail?.claim])

    const updateClaim = () => {
        console.log("Attempting to update claim")
        dispatch(update(
            {
                body: {
                    _model: "WarrantyClaim",
                    params: { _id: claimDetail._id },
                    update: claimDetail
                },

                dispatchDetail: (response) => ({
                    type: GET_WARRANTY_DETAIL,
                    payload: { data: { claim: response.data.doc } }
                }),
            }
        ))

    }

    // get user details
    // get dealer details

    console.log(warrantyDetail)

    if (!warrantyUser || !warrantyDetail || !warrantyDetail.claim) return <Text>Loading...</Text>

    return (
        <div style={{
            // border: '1px solid red',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 60px)',
            marginTop: '60px'
        }}>
            <div>

                <div style={{
                    borderBottom: '1px #333 solid',
                    display: 'flex',
                    padding: '1rem'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flexGrow: 1,
                    }}>
                        <Text type={4}>Claim Detail: {warrantyDetail.claim._id}</Text>
                        <Text type={4}>{warrantyUser.firstName + ' ' + warrantyUser.lastName}</Text>
                        <Text type={4}>{warrantyUser.email}</Text>
                        <Text type={4}>User Since: {warrantyUser.createdAt}</Text>
                        <div style={{
                            margin: '8px 0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center"
                        }}>
                            {editStatus ?
                                <>

                                    <TiTimes size={20} onClick={() => setEditStatus(false)} />

                                    <TiTick size={20} onClick={() => {
                                        updateClaim()
                                        setEditStatus(false)
                                    }} />

                                    <Text type={4}>Status:</Text>
                                    <select onChange={e => setClaimDetail({ ...claimDetail, status: e.target.value })} value={claimDetail.status}>
                                        <option value="PENDING">PENDING</option>
                                        <option value="COMPLETE">COMPLETE</option>
                                    </select>
                                </>
                                :
                                <>
                                    <TiEdit size={20} onClick={() => setEditStatus(true)} />
                                    <Text type={4}>Status: {warrantyDetail.claim.status}</Text>
                                </>
                            }
                        </div>
                        {/* <Text type={4}>{warrantyUser.phone}</Text> */}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flexGrow: 1,
                    }}>
                        <Text type={4}>{warrantyDetail.claim.userProduct.productDetail.name}</Text>
                        <Text type={4}>Order Number: {warrantyDetail.claim.userProduct.orderNumber}</Text>
                        <Text type={4}>Serial Number: {warrantyDetail.claim.userProduct.serialNumber}</Text>
                        <Text type={4}>Purchase Date: {moment(warrantyDetail.claim.userProduct.purchaseDate).format('ll')}</Text>
                        <Text type={4}>Install Date: {moment(warrantyDetail.claim.userProduct.installDate).format('ll')}</Text>
                    </div>
                </div>

                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '1rem',
                        flex: 1,
                    }}>
                        <Text type={2}>Warranty Flow</Text>
                        {warrantyDetail.claim.flow.map((item, index) => (
                            <>
                                <Text type={4}>{item.title}</Text>
                                {item.answer.map((answer, index) => (
                                    <Text type={4}> - {answer}</Text>
                                ))}
                            </>
                        ))}
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '1rem',
                        flex: 1,
                    }}>
                        <Text type={2}>Notice</Text>

                        <div style={{
                            padding: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            textAlign: 'left'
                        }}>

                            <div style={{
                                margin: '8px 0px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                textAlign: 'left'
                            }}>
                                <Text type={3}>Error Codes</Text>
                                {checkErrorCodes(warrantyDetail.claim.flow).map((item, index) => (
                                    <>
                                        <Text>{item.notice}</Text>
                                        <Text>{item.suggestion}</Text>
                                    </>
                                ))}
                            </div>

                            <div style={{
                                margin: '8px 0px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                textAlign: 'left'
                            }}>
                                <Text type={3}>Cell Efficiency: {getCellEfficiency(warrantyDetail.claim.flow).efficiency}%</Text>
                                <Text>{getCellEfficiency(warrantyDetail.claim.flow).recommendation}</Text>
                            </div>

                        </div>


                    </div>
                </div>
            </div>



            <div style={{
                // border: '1px solid blue',
                borderRadius: '16px 16px 0px 0px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <Button name={"Back"} onClick={() => navigate(-1)} />
            </div>




        </div>
    )

}

export default ClaimDetail