const Input = ({ type, onChange, styleType, value }) => {
    let style = {
        border: '1px solid rgba(0, 0, 0, .25)',
        borderRadius: '8px',
        width: '80%',
        height: '50px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, .5)',
        margin: '16px 0px',
        padding: '1rem'
    }

    if (styleType === 1) {
        style.color = 'black'
        style.fontSize = '3rem'
    }

    return (
        <input
            autoFocus
            style={style}
            type={type}
            onChange={onChange}
            value={(value === null ? '' : value)}
        />
    )
}

export default Input