import { GET_NOTIFICATIONS } from "../../types"


import axios from 'axios'

export const deleteDoc = ({ body, callback }) => async (dispatch) => {

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/delete`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    // console.log(response)
    callback && await callback()
}