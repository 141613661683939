import { useEffect } from 'react'
import Table from '../../../common/table';
import Button from '../../../common/button';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_WARRANTY_CLAIMS } from '../../../store/types';
import { get } from '../../../store/actions/crud/get';

const WarrantyClaim = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.warrantyClaims)
    const { data: products } = useSelector(state => state.products)

    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "WarrantyClaim",
                },

                dispatchDetail: (response) => ({
                    type: GET_WARRANTY_CLAIMS,
                    payload: { data: response.data.doc }
                }),
            }
        ))
    }, [])

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Warranty Claims"} data={data} onClick={(obj) => navigate('/claim-detail', { state: { claim: obj } })} />
            </div>
        </div>
    )
}

export default WarrantyClaim
