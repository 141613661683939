import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import RootReducer from './reducers/index'


const persistConfig = {
    key: 'calimar',
    version: 1,
    storage,
    whitelist: ['auth', 'notification']
}

const persistedReducer = persistReducer(persistConfig, RootReducer)



// export default function () {

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
    // return { store, persistor }
// }


// export const persistor = persistStore(store)