import './App.css';

import { Provider } from 'react-redux'
import { store, persistor } from '../src/store/index'
import { PersistGate } from 'redux-persist/integration/react'

// COMPONENTS
import MainContainer from './components/mainContainer';
import Notification from './common/notification';


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <Notification />
        <MainContainer />

      </PersistGate>
    </Provider>
  );
}

export default App;