import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux';
import { adminLogin } from '../../../store/actions/auth/adminLogin';

import Button from "../../../common/button";

const AdminLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = () => {
        dispatch(adminLogin({ email, password }))
    }

    return (
        <div style={{
            width: '100%',
            marginTop: '60px',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <div className='container'>
                <h2 className='text-lg font-bold text-center'>Admin Login</h2>
                <form className='flex flex-col p-4 '>
                    <label className='p-1 flex flex-col'>
                        Email Address
                        <input type="email" onChange={e => setEmail(e.target.value)} />
                    </label>
                    <label className='p-1 flex flex-col'>
                        Password
                        <input type="password" onChange={e => setPassword(e.target.value)} />
                    </label>
                </form>
                <div className='flex justify-between p-2'>
                    <Button type={3} name={"Cancel"} onClick={() => navigate("/")} />
                    <Button name={"Login"} onClick={handleSubmit} />
                </div>

            </div>
        </div>
    )

}

export default AdminLogin