import { GET_NOTIFICATIONS, CLEAR_NOTIFICATIONS, NOTIFICATIONS_LOADING } from '../types'

const initialState = {
    msg: null,
    status: null,
    type: null,
    isLoading: false
}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_NOTIFICATIONS:
            return {
                ...state,
                msg: action.payload.msg,
                status: action.payload.status,
                type: action.payload.type,
                isLoading: false
            }

        case NOTIFICATIONS_LOADING:
            return {
                ...state,
                isLoading: true
            }

        case CLEAR_NOTIFICATIONS:
            return initialState

        default:
            return state
    }
}

export default notificationReducer