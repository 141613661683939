import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../common/button';

import { useDispatch } from 'react-redux';
import { create } from '../../../store/actions/crud/create';
import { get } from '../../../store/actions/crud/get'
import { GET_SYSTEM_WARRANTY_FLOW } from '../../../store/types';
import { useNavigate } from 'react-router-dom';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function Add({ products }) {
    // alert(`total products: ${products.length}`)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    const [name, setName] = useState("")
    const [flow, setFlow] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = () => {

        const newFlow = {
            name,
            products: selectedProducts,
            // flow,

        }

        // return console.log(newFlow)

        dispatch(create(
            {
                body: {
                    _model: "WarrantyFlow",
                    params: newFlow
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "WarrantyFlow"
                        },
                        dispatchDetail: (response) => ({
                            type: GET_SYSTEM_WARRANTY_FLOW,
                            payload: { warrantyFlow: response.data.doc }
                        }),
                    }
                ))
            }
        ))

        setIsOpen(false);
    }


    return (
        <div>
            <div className="p-4" >
                <Button name={"Add A Warranty Flow"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add Warranty Flow Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>New Warranty Flow</h2>

                <form className='flex flex-col p-4 '>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Name:</p>
                        <input type="text" onChange={e => setName(e.target.value)} value={name} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Select Products:</p>
                        <select onChange={(e) => setSelectedProducts([JSON.parse(e.target.value)])}>
                            <option>Select Product</option>
                            {products.map((item, index) => (
                                <option value={JSON.stringify(item)}>{item.name}</option>
                            ))}
                        </select>
                    </label>

                </form>

                <div className='flex justify-between p-2'>
                    <Button name={"Cancel"} onClick={closeModal} />
                    {/* <Button name={"Create"} onClick={handleSubmit} /> */}
                    <Button name={"Create Flow"} onClick={() => navigate('/create-flow', { state: { name, selectedProducts } })} />
                </div>
            </Modal>
        </div>
    );
}

export default Add