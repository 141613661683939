export const checkErrorCodes = (flow) => {
    let array = []
    let notice = ''
    let suggestion = ''


    for (let i = 0; i < flow.length; i++) {
        // Check for HOT
        if (flow[i].answer.includes("HOT")) {
            array.push({
                notice: "HOT error code is present",
                suggestion: 'Check cell thermistor. If system produces random or unusually HIGH temperatures, cell is bad. Recommend replacing cell.'

            })
        }

        if (flow[i].answer.includes("COLD")) {
            array.push({
                notice: "COLD error code is present",
                suggestion: 'Check cell thermistor. If system produces random or unusually LOW temperatures, cell may be bad. Check if actual pool water is below 65 degrees farenheit. If yes, wait for warmer temperatures and re test. If no, Recommend replacing cell.'
            })
        }
    }

    return array
}

export const getCellEfficiency = (flow) => {
    let actualSalinity = 0
    let instantSalinity = 0
    let efficiency = 0
    let recommendation = ''

    for (let i = 0; i < flow.length; i++) {
        if (flow[i].title.toUpperCase().includes("ACTUAL") && flow[i].title.toUpperCase().includes("SALINITY")) {
            actualSalinity = flow[i].answer[0]
        }
        if (flow[i].title.toUpperCase().includes("INSTANT") && flow[i].title.toUpperCase().includes("SALINITY")) {
            instantSalinity = flow[i].answer[0]
        }
    }

    efficiency = parseFloat((instantSalinity / actualSalinity) * 100).toFixed(2)
    if (efficiency < 74) {
        recommendation = 'Check system is set to correct cell type. If so, cell might be older and recommendation is to replace cell due to low cell efficiency'
    }
    if (efficiency >= 75) {
        recommendation = 'Cell efficiency is OK'
    }

    // console.log({
    //     actualSalinity,
    //     instantSalinity,
    //     efficiency
    // })

    return ({
        actualSalinity,
        instantSalinity,
        efficiency,
        recommendation
    })

}