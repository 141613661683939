import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getPools } from '../../../store/actions/pools/get';
import { deletePool } from '../../../store/actions/pools/delete';

import Table from '../../../common/table';

import Addpool from './addpool'

import { GET_POOLS } from '../../../store/types';
import { get } from '../../../store/actions/crud/get'
import { deleteDoc } from '../../../store/actions/crud/delete';

const Pools = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { data, isLoading } = useSelector(state => state.pools)

    useEffect(() => {
        if (user) {
            dispatch(get({
                body: {
                    _model: "Pool",
                    params: {
                        userId: 1
                    },
                    attributes: {
                        shape: 1,
                        totalGallons: 1,
                        // userId: 1,
                    }
                },
                dispatchDetail: (response) => (
                    dispatch({
                        type: GET_POOLS,
                        payload: { pools: response.data.doc }
                    })
                )
            }))
        }


    }, [])



    const deleteFunction = (obj) => {
        dispatch(deleteDoc(
            {
                body: {
                    _model: "Pool",
                    params: {
                        _id: obj._id
                    },
                },
                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "Pool",
                            params: {
                                userId: user._id
                            }
                        }
                    }

                )),
                dispatchDetail: (response) => ({
                    type: GET_POOLS,
                    payload: { pools: response.data.doc }
                }),
            }
        ))


        // dispatch(deletePool(obj._id, user._id))
    }


    if (isLoading) {
        return <p>loading</p>
    }



    console.log("DATA", data)

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Pools"} data={data || []} addDelete={true} deleteFunction={deleteFunction} />
                {console.log(user._id)}
                <Addpool userId={user._id} />

            </div>
        </div>
    )
}

export default Pools
