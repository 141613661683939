import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import Text from '../../../../common/text'
import Select from '../../../../common/form/select'
import Answers from './answers'
import Button from '../../../../common/button'

// REDUX
import { create } from '../../../../store/actions/crud/create'
import { get } from '../../../../store/actions/crud/get'
import { GET_PRODUCTS, GET_SYSTEM_WARRANTY_FLOW, GET_WARRANTY_CLAIMS } from '../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'


const C1 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Redux State
    const { warrantyFlow } = useSelector(state => state.systemProducts)
    const { data: userProducts } = useSelector(state => state.products)
    const { user } = useSelector(state => state.auth)

    // State
    const [flow, setFlow] = useState([])
    const [userProduct, setUserProduct] = useState(null)
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [currentNode, setCurrentNode] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [selectedWarrantyFlow, setSelectedWarrantyFlow] = useState(null)
    const [warrantyProduct, setWarrantyProduct] = useState(null)

    // Get warranty flows and user products
    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "WarrantyFlow"
                },
                dispatchDetail: (response) => ({
                    type: GET_SYSTEM_WARRANTY_FLOW,
                    payload: { warrantyFlow: response.data.doc }
                })
            }
        ))

        dispatch(get(
            {
                body: {
                    _model: "UserProduct",
                    params: { userId: user._id }
                },

                dispatchDetail: (response) => ({
                    type: GET_PRODUCTS,
                    payload: { products: response.data.doc }
                }),
            }
        ))
    }, [])

    // Search all warranty flows that includes user product
    // When found set selected warranty flow state
    const getWarrantyFlow = () => {
        if (warrantyFlow && userProduct) {
            for (let i = 0; i < warrantyFlow.length; i++) {
                for (let j = 0; j < warrantyFlow[i].products.length; j++) {
                    if (warrantyFlow[i].products[j].name === userProduct.productDetail.name) {
                        setCurrentNode(warrantyFlow[i]?.flow[currentIndex])
                        setSelectedWarrantyFlow(i)
                    }
                }

            }
        }
    }

    const next = async () => {
        // Create flow object
        let newFlow = {
            title: currentNode.title,
            answer: selectedAnswer
        }

        // Add flow object to state
        // Clear selected answer state
        setFlow([...flow, newFlow])
        setSelectedAnswer(null)

        // Increment progress 
        let lastIndex = (warrantyFlow[selectedWarrantyFlow]?.flow.length - 1)
        if (currentIndex < lastIndex) {
            setCurrentIndex(currentIndex + 1)
            setCurrentNode(warrantyFlow[selectedWarrantyFlow].flow[currentIndex + 1])
        }

        // Claim is complete
        // Create db doc and inform user
        if (currentIndex === lastIndex) {

            const newWarrantyClaim = {
                userId: user._id,
                userProduct,
                flow,
            }

            await dispatch(create(
                {
                    body: {
                        _model: "WarrantyClaim",
                        params: newWarrantyClaim
                    },

                }
            ))


            // When complete, send user to complete page
            navigate('/claim-complete')

        }

    }

    // Set selected user product state
    // Get warranty flow after user selects product
    const selectUserProduct = () => {
        setWarrantyProduct(userProduct)
        getWarrantyFlow()
    }

    if (!userProducts) {
        return <p>Loading userProducts</p>
    }

    // Ask user which product they want
    if (!warrantyProduct) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'calc(100vh - 73px)'
            }}>
                <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Text type={1}>Which product do you want to warranty?</Text>
                    <Select options={userProducts} label={"Select a product"} onChange={e => setUserProduct(JSON.parse(e.target.value))} />
                </div>

                <div style={{
                    borderRadius: '16px 16px 0px 0px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Button type={2} name={"Cancel"} onClick={() => navigate('/')} />

                    <Button type={userProduct ? 1 : 3} name={"Continue"} onClick={selectUserProduct} />
                </div>

            </div>
        )
    }

    if (!currentNode) {
        return <p>Loading currentNode</p>
    }

    // Warranty claim flow
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100vh - 73px)'
        }}>
            <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Text type={1}>{currentNode.title}</Text>
                <Text type={2}>{currentNode.subtitle}</Text>

                <Answers answers={currentNode.answers} answerType={currentNode.answerType} setSelectedAnswer={setSelectedAnswer} selectedAnswer={selectedAnswer} />
            </div>

            <div style={{
                borderRadius: '16px 16px 0px 0px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between'
            }}>

                <Button type={2} name={"Cancel"} onClick={() => navigate('/')} />

                {selectedAnswer?.length > 0 ?
                    <Button type={1} name={"Next"} onClick={next} />
                    : <Button type={3} name={"Next"} />
                }


            </div>

        </div>
    )

}

export default C1