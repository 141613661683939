import { GET_NOTIFICATIONS } from "../../../types"
import { getAdminProducts } from "./get"

import axios from 'axios'

export const addProduct = (product) => async (dispatch) => {
    const body = {
        _model: product.type._model,
        params: product
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/create`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)
    dispatch(getAdminProducts())
}