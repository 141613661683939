import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../common/button';

import { useDispatch } from 'react-redux';
import { get } from '../../../store/actions/crud/get'
import { create } from '../../../store/actions/crud/create'
import { GET_SYSTEM_PRODUCTS } from '../../../store/types'



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function AddProduct({ types, manufacturers }) {
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    const [product, setProduct] = useState({})

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }


    const handleSubmit = () => {
        dispatch(create(
            {
                body: {
                    _model: "Product",
                    params: product
                },

                callback: () => dispatch(get({
                    body: {
                        _model: "Product",
                        attributes: {
                            name: 1,
                            serialNumber: 1,
                            modelNumber: 1,
                            manufacturerId: 1,
                            description: 1,
                        }
                    },
                    dispatchDetail: (response) => ({
                        type: GET_SYSTEM_PRODUCTS,
                        payload: { products: response.data.doc }
                    })
                }))


            }

        ))

        setIsOpen(false);
    }

    console.log("PRODUCT===", product)
    console.log("types===", types)

    return (
        <div>
            <div className="p-4" >
                <Button name={"Add A Product"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add Product Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>Add Product</h2>
                <form className='flex flex-col p-4 '>

                    {/* <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Product Type:</p>
                        <select id="type" name="type" onChange={e => setProduct({ ...product, typeId: e.target.value })}>
                            <option>Select Product Type</option>
                            {types.map((item, index) => (
                                <option value={JSON.stringify(item._id)}>{item.name}</option>
                            ))}
                        </select>
                    </label> */}

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Name:</p>
                        <input type="text" onChange={e => setProduct({ ...product, name: e.target.value })} />
                    </label>

                    {/* <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Slug:</p>
                        <input type="text" onChange={e => setProduct({ ...product, slug: e.target.value })} />
                    </label> */}

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Description:</p>
                        <input type="text" onChange={e => setProduct({ ...product, description: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Model Number:</p>
                        <input type="text" onChange={e => setProduct({ ...product, modelNumber: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Manufacturer:</p>
                        <select id="type" name="type" onChange={e => setProduct({ ...product, manufacturer: JSON.parse(e.target.value) })}>
                            <option>Select Manufacturer</option>
                            {manufacturers.map((manf, index) => (
                                <option value={JSON.stringify(manf)}>{manf.name}</option>
                            ))}
                        </select>
                    </label>

                </form>
                <div className='flex justify-between p-2'>
                    <Button type={3} name={"Cancel"} onClick={closeModal} />
                    <Button type={(product.name && product.description && product.modelNumber && product.manufacturer ? 1 : 3)} name={"Create"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default AddProduct