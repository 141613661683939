import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../common/button';

import { useDispatch } from 'react-redux';
import { create } from '../../../store/actions/crud/create';
import { getManufacturers } from '../../../store/actions/admin/products/getManufacturers';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function AddManufacturer() {
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    const [manufacturer, setManufacturer] = useState({})

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = () => {

        dispatch(create(
            {
                body: {
                    _model: "Manufacturer",
                    params: manufacturer
                },

                callback: () => dispatch(getManufacturers())
            }
        ))



        setIsOpen(false);
    }


    return (
        <div>
            <div className="p-4" >
                <Button name={"Add A Manufacturer"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add a Manufacturer"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>New Manufacturer</h2>
                <form className='flex flex-col p-4 '>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Name:</p>
                        <input type="text" onChange={e => setManufacturer({ ...manufacturer, name: e.target.value })} />
                    </label>

                    {/* <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Slug:</p>
                        <input type="text" onChange={e => setManufacturer({ ...manufacturer, slug: e.target.value })} />
                    </label> */}

                </form>
                <div className='flex justify-between p-2'>
                    <Button type={3} name={"Cancel"} onClick={closeModal} />
                    <Button type={(manufacturer.name ? 1 : 3)} name={"Create"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default AddManufacturer