import { GET_POOLS, POOLS_LOADING, GET_NOTIFICATIONS } from "../../types"

import axios from 'axios'

export const getPools = (userId) => async (dispatch) => {
    console.log("GETTING POOLS", userId)
    dispatch({ type: POOLS_LOADING })

    const body = {
        _model: "Pool",
        params: {
            userId: userId
        },
        attributes: {
            shape: 1,
            totalGallons: 1,
            // userId: 1,
        }
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/get`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)

    dispatch({
        type: GET_POOLS,
        payload: { pools: response.data.doc }
    })
}