import React, { Fragment } from 'react'
import { useSelector } from "react-redux"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// COMPONENTS
import NavBar from "./navbar"

// ADMIN
import AdminDashboard from "./adminDashBoard";
import AdminLogin from './auth/admin/login';

// USER
import UserDashboard from './userDashboard';

// WARRANTY SYSTEM
import C1 from './userDashboard/warrantyClaim/process/c1';
import CreateFlow from './adminDashBoard/warrantyFlow/createFlow';
import ClaimDetail from './adminDashBoard/claimDetail';
import ClaimComplete from './userDashboard/warrantyClaim/process/claimComplete';

const AuthorizedRoutes = ({ user, admin }) => {
    console.log("displaying authorized routes")

    if (user) {
        console.log("displaying user routes")
        return (
            <Router>
                <NavBar />

                <Routes>
                    <Route path="/" element={<UserDashboard />} />
                    <Route path="/c1" element={<C1 />} />
                    <Route path="/claim-complete" element={<ClaimComplete />} />
                </Routes>
            </Router>
        )
    }

    if (admin) {
        console.log("displaying admin routes")
        return (
            <Router>
                <NavBar />

                <Routes>
                    <Route path="/" element={<AdminDashboard />} />
                    <Route path="/create-flow" element={<CreateFlow />} />
                    <Route path="/claim-detail" element={<ClaimDetail />} />
                </Routes>
            </Router>
        )
    }

    console.log("returning error")

}

const UnauthorizedRoutes = () => {
    console.log("displaying unauthorized routes")
    return (
        <Router>
            <NavBar />
            <Routes>
                {/* Admin login page */}

                {/* <Route path="/" element={<AdminLogin />} /> */}
                <Route path="/admin-login" element={<AdminLogin />} />


            </Routes>
        </Router>
    )
}


const MainContainer = () => {
    const { user, admin } = useSelector(state => state.auth)

    return (
        <div>
            {/* <NavBar /> */}
            <div style={{
                // marginTop: '60px'
            }}>


                {
                    user || admin ? <AuthorizedRoutes user={user} admin={admin} /> : <UnauthorizedRoutes />
                }

            </div>
        </div>
    )
}

export default MainContainer