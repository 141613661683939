import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../common/button';

import { useDispatch } from 'react-redux';
import { get } from '../../../store/actions/crud/get'
import { create } from '../../../store/actions/crud/create'
import { GET_ADMIN_USERS, GET_NOTIFICATIONS, GET_SYSTEM_PRODUCTS } from '../../../store/types'
import { newAdmin } from '../../../store/actions/auth/newAdmin';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function Add() {
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [admin, setAdmin] = useState({})

    useEffect(() => {
        setAdmin({ ...admin, password: 'asdf' })
    }, [])

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }


    const handleSubmit = () => {
        var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!filter.test(admin.email)) {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: 'Please provide a valid email address', type: "error" }
            })
            // alert('Please provide a valid email address');
            return false;
        }


        dispatch(newAdmin(
            {
                body: {
                    _model: "Admin",
                    params: admin
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "Admin",
                            attributes: {
                                email: 1,
                                firstName: 1,
                                lastName: 1,
                                createdAt: 1,
                                type: 1,
                            }

                        },
                        dispatchDetail: (response) => ({
                            type: GET_ADMIN_USERS,
                            payload: { data: response.data.doc }
                        }),
                    }
                ))


            }

        ))

        setIsOpen(false);
    }


    return (
        <div>
            <div className="p-4" >
                <Button name={"Add Admin User"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add admin user Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>Add Admin User</h2>
                <form className='flex flex-col p-4 '>


                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">First Name:</p>
                        <input type="text" onChange={e => setAdmin({ ...admin, firstName: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Last Name:</p>
                        <input type="text" onChange={e => setAdmin({ ...admin, lastName: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Email:</p>
                        <input type="text" onChange={e => setAdmin({ ...admin, email: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Auto Generated Password:</p>
                        <p className="font-semibold">{admin.password}</p>
                    </label>


                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">type:</p>
                        <select id="type" name="type" onChange={e => setAdmin({ ...admin, type: e.target.value })}>
                            <option>Select type</option>
                            <option type={'Master'}>Master</option>
                            <option type={'Dealer'}>Dealer</option>
                            <option type={'Manufacturer'}>Manufacturer</option>
                        </select>
                    </label>

                </form>
                <div className='flex justify-between p-2'>
                    <Button type={3} name={"Cancel"} onClick={closeModal} />
                    <Button type={(admin.firstName && admin.lastName && admin.email && admin.type && admin.password ? 1 : 3)} name={"Create"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default Add


// firstName: {
//     type: String,
//         required: false
// },

// lastName: {
//     type: String,
//         required: false
// },

// email: {
//     type: String,
//         required: true
// },

// password: {
//     type: String,
//         required: true
// },

// type: {
//     type: String,
//         required: true
// }, 