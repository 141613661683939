import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Table from "../../../common/table"
import { deleteDoc } from "../../../store/actions/crud/delete"
import { get } from "../../../store/actions/crud/get"
import { GET_ADMIN_USERS } from "../../../store/types"
import Add from "./add"

const Users = () => {
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.system)
    const [admin, setAdmin] = useState({})

    useEffect(() => {
        dispatch(get({
            body: {
                _model: "Admin",
                attributes: {
                    email: 1,
                    firstName: 1,
                    lastName: 1,
                    createdAt: 1,
                    type: 1,
                }

            },
            dispatchDetail: (response) => ({
                type: GET_ADMIN_USERS,
                payload: { data: response.data.doc }
            }),
        }))
    }, [])

    const deleteFunction = (obj) => {

        dispatch(deleteDoc(
            {
                body: {
                    _model: "Admin",
                    params: {
                        _id: obj._id
                    },
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "Admin",
                            attributes: {
                                email: 1,
                                firstName: 1,
                                lastName: 1,
                                createdAt: 1,
                                type: 1,
                            }

                        },
                        dispatchDetail: (response) => ({
                            type: GET_ADMIN_USERS,
                            payload: { data: response.data.doc }
                        }),
                    }
                ))
            }
        ))
    }

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Admin Users"} data={data} addDelete={true} deleteFunction={deleteFunction} />
                <Add />

            </div>
        </div>
    )
}

export default Users




// 13 41 54 14 31 11
// l  a  s  t  e  d 

// 43 33 31 
// o  n  e

// 32 34 33 11 24 31 11 
// h  u  n  d  r  e  d

// 14 31 33
// t  e  n

// 53 42 33 34 14 31 54
// m  i  n  u  t  e  s    

