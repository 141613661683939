import { GET_NOTIFICATIONS, LOGIN_USER } from "../../types"

import axios from 'axios'

export const login = (email, password) => async (dispatch) => {

    const body = {
        _model: "User",
        params: {
            email: email,
            password: password
        }
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login-user`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)

    dispatch({
        type: GET_NOTIFICATIONS,
        payload: { msg: `Welcome Back ${response.data.account.firstName ? response.data.account.firstName : ""}`, type: "success" }
    })

    dispatch({
        type: LOGIN_USER,
        payload: { token: response.data.token, user: response.data.account }
    })
}