import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../common/button';

import { useDispatch } from 'react-redux';
import { get } from '../../../store/actions/crud/get'
import { create } from '../../../store/actions/crud/create'
import { GET_PRODUCTS } from '../../../store/types';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function AddProduct({ userId, types, dealers, products }) {
    const dispatch = useDispatch()


    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [product, setProduct] = useState({ userId })


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = () => {
        // if (!product.userId) {
        //     return alert("no product user id")
        // }

        dispatch(create({
            body: {
                _model: "UserProduct",
                params: product
            },
            callback: () => dispatch(get({
                body: {
                    _model: "UserProduct",
                    params: {
                        userId
                    },
                    attributes: {
                        // poolId: 1,
                        // userId: 1,
                        // userPool: 1,
                        // dealerId: 1,
                        productDetail: 1,
                        orderNumber: 1,
                        serialNumber: 1,
                        purchaseDate: 1,
                        installDate: 1
                        // userId: 1,
                    }
                },
                dispatchDetail: (response) => (
                    dispatch({
                        type: GET_PRODUCTS,
                        payload: { products: response.data.doc }
                    })
                )
            }))
        }))


        setIsOpen(false);
    }

    return (
        <div>
            <div className="p-4" >
                <Button name={"Add A Product"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add Product Modal"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>Add Product</h2>
                <form className='flex flex-col p-4 '>

                    {/* <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Product Type:</p>
                        <select id="type" name="type" onChange={e => setProduct({ ...product, typeId: JSON.parse(e.target.value)._id })}>
                            <option>Select Product Type</option>
                            {types.map((item, index) => (
                                <option key={JSON.stringify(item._id + index)} value={JSON.stringify(item)}>{item.name}</option>
                            ))}

                        </select>
                    </label> */}

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Dealer:</p>
                        <select id="type" name="type" onChange={e => setProduct({ ...product, dealer: JSON.parse(e.target.value) })}>
                            <option>Select Dealer</option>
                            {dealers.map((item, index) => (
                                <option key={JSON.stringify(item._id + index)} value={JSON.stringify(item)}>{item.name}</option>
                            ))}

                        </select>
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Product:</p>
                        <select id="type" name="type" onChange={e => setProduct({ ...product, productDetail: JSON.parse(e.target.value) })
                        }>
                            <option>Select Product</option>
                            {products.map((item, index) => (
                                <option key={JSON.stringify(item._id + index)} value={JSON.stringify(item)}>{item.name}</option>
                            ))}

                        </select>
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Order-Number:</p>
                        <input type="text" onChange={e => setProduct({ ...product, orderNumber: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Serial Number:</p>
                        <input type="text" onChange={e => setProduct({ ...product, serialNumber: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Purchase-Date:</p>
                        <input type="date" onChange={e => setProduct({ ...product, purchaseDate: e.target.value })} />
                    </label>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Install-Date</p>
                        <input type="date" onChange={e => setProduct({ ...product, installDate: e.target.value })} />
                    </label>

                </form>
                <div className='flex justify-between p-2'>
                    <Button name={"Cancel"} onClick={closeModal} />
                    <Button name={"Create"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default AddProduct