import { useEffect } from 'react'
import Table from '../../../common/table';
import Button from '../../../common/button';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_WARRANTY_CLAIMS } from '../../../store/types';
import { get } from '../../../store/actions/crud/get';
import { deleteDoc } from '../../../store/actions/crud/delete';

const WarrantyClaim = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.warrantyClaims)
    const { user } = useSelector(state => state.auth)
    const { data: products } = useSelector(state => state.products)


    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "WarrantyClaim",
                    params: {
                        userId: user._id
                    },
                    attributes: {
                        // _id: 1,
                        userProduct: 1,
                        status: 1,
                        createdAt: 1,
                    }

                },

                dispatchDetail: (response) => ({
                    type: GET_WARRANTY_CLAIMS,
                    payload: { data: response.data.doc }
                }),
            }
        ))
    }, [])

    const deleteFunction = (obj) => {
        dispatch(deleteDoc(
            {
                body: {
                    _model: "WarrantyClaim",
                    params: {
                        _id: obj._id
                    },
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "WarrantyClaim",
                            params: {
                                userId: user._id
                            },
                        },

                        dispatchDetail: (response) => ({
                            type: GET_WARRANTY_CLAIMS,
                            payload: { data: response.data.doc }
                        }),
                    }

                )),

            }
        ))
    }

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>

                <Table title={"Warranty Claims"} data={data} addDelete={true} deleteFunction={deleteFunction} />

                {products.length > 0 ?
                    <Button name={"Start Claim"} onClick={() => navigate("/c1")} />
                    : <p>Please add a product before filing a warranty claim.</p>}


            </div>
        </div>
    )
}

export default WarrantyClaim
