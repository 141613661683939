import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../common/button';

import { useDispatch } from 'react-redux';
import { create } from '../../../store/actions/crud/create';
import { get } from '../../../store/actions/crud/get'
import { GET_SYSTEM_PRODUCT_DEALERS } from '../../../store/types'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function AddDealer() {
    const dispatch = useDispatch()
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    const [dealer, setDealer] = useState({})

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#222';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleSubmit = () => {
        if (!dealer) return alert("Error: no dealer details to submit")


        dispatch(create(
            {
                body: {
                    _model: "Dealer",
                    params: dealer
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "Dealer"
                        },
                        dispatchDetail: (response) => ({
                            type: GET_SYSTEM_PRODUCT_DEALERS,
                            payload: { dealers: response.data.doc }
                        }),
                    }
                ))
            }
        ))

        setIsOpen(false);
    }


    return (
        <div>
            <div className="p-4" >
                <Button name={"Add A Dealer"} onClick={openModal} />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add a Dealer"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)} className='text-lg font-bold text-center'>New Dealer</h2>
                <form className='flex flex-col p-4 '>

                    <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Name:</p>
                        <input type="text" onChange={e => setDealer({ ...dealer, name: e.target.value })} />
                    </label>

                    {/* <label className='p-1 flex flex-col'>
                        <p className="font-semibold">Slug:</p>
                        <input type="text" onChange={e => setDealer({ ...dealer, slug: e.target.value })} />
                    </label> */}

                </form>
                <div className='flex justify-between p-2'>
                    <Button name={"Cancel"} onClick={closeModal} />
                    <Button name={"Create"} onClick={handleSubmit} />
                </div>
            </Modal>
        </div>
    );
}

export default AddDealer