import React, { Fragment } from 'react'
import { camelCaseConverter } from '../utils/camelCaseConverter'
import moment from 'moment'
import ConfirmDelete from './util/confirmDelete'

const Table = ({ data, addDelete, deleteFunction, title, showId, onClick }) => {
    // console.log("TABLE DATA", data)

    // const confirmDelete = (obj) => {
    //     if (window.confirm("Are you sure you want to delete this?")) {
    //         deleteFunction(obj)
    //     }
    // }

    if (!data) {
        return <p>{title} data error.</p>
    }

    if (data.length === 0) {
        return <p>There are no {title}</p>
    }

    return (
        <Fragment>
            <h1 className='p-4 text-2xl'>Your {title}</h1>
            <table className='border-separate border-spacing-2 border border-slate-500 w-full border-solid border-2 table-auto'>

                <tr>
                    {Object.keys(data[0]).map((value) => {
                        if (value === "_id" && !showId) return null
                        return <th className="border border-slate-600 p-2">{camelCaseConverter(value)}</th>
                    })}
                </tr>

                {data.map((obj, index) => (
                    <tr onClick={() => onClick(obj)} style={{
                        cursor: (onClick ? 'pointer' : null)
                    }}>
                        {Object.values(obj).map((value, index) => {
                            if (Object.keys(obj)[index] === "_id" && !showId) return null

                            if (Object.keys(obj)[index] === "installDate" || Object.keys(obj)[index] === "purchaseDate" || Object.keys(obj)[index] === "createdAt") {
                                return (
                                    <td className="border border-slate-600 p-2">{moment(value).format("LL")}</td>
                                )
                            }

                            if (typeof value === 'object') {
                                console.log(value, typeof value)

                                if (value.productDetail) {
                                    return <td className="border border-slate-600 p-2">{value.productDetail.name}</td>
                                }

                                return (
                                    <td className="border border-slate-600 p-2">{value.name}</td>
                                )

                            }

                            return (
                                <td className="border border-slate-600 p-2">{value}</td>
                            )
                        })}

                        {/* {addDelete && <td className="border border-slate-600 p-2 cursor-pointer" onClick={() => confirmDelete(obj)}>Delete</td>} */}
                        {addDelete && <td className="border border-slate-600 p-2 cursor-pointer" ><ConfirmDelete object={obj} deleteFunction={deleteFunction} /></td>}

                    </tr>
                ))}
            </table>
        </Fragment>
    )
}

export default Table