import React from 'react'
import Button from '../../common/button'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { signout } from '../../store/actions/auth/signout'
import Text from '../../common/text'

const Authorized = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, admin } = useSelector(state => state.auth)

    if (user) {
        return (
            <div style={{
                // border: '1px solid blue',
                borderRadius: '0px 0px 16px 16px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

            }}>
                <img src="images/calimarLogo.png" width={50} height={50} />
                <Text type={2}>Calimar Warranty System</Text>

                <Button name={"Sign Out"} onClick={() => {
                    dispatch(signout())
                    navigate('/')
                }} />
            </div >
        )
    }

    if (admin) {
        return (
            <div style={{
                // border: '1px solid blue',
                borderRadius: '0px 0px 16px 16px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, .5)',
                padding: '.5rem 1.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                position: 'fixed',
                top: 0,
                backgroundColor: '#fff',
                // marginBottom: '60px',

            }}>
                <img src="images/calimarLogo.png" width={50} height={50} />
                <Button name={"Sign Out"} onClick={() => {
                    dispatch(signout())
                    navigate('/')
                }} />
            </div >
        )

    }

}

export default Authorized