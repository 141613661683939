import { TiTrash, TiPlus } from "react-icons/ti";

const Button = ({ name, onClick, type, style, icon }) => {

    let styleType = {}

    if (type === 2) {
        styleType.backgroundColor = "#f52525"
    }

    if (type === 3) {
        styleType.backgroundColor = "#767676"
    }

    const iconDisplay = () => {

        switch (icon) {
            case "Trash":
                return <TiTrash size={20} style={{ marginRight: '8px' }} />;

            case "Plus":
                return <TiPlus />;

            default:
                return <TiPlus />;
        }

    }

    return (
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" style={{ ...styleType, ...style }} onClick={onClick}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {icon ? iconDisplay() : null}
                {name}
            </div>
        </button>
    )
}

export default Button