import { LOGIN_USER, LOGIN_ADMIN, SIGN_OUT } from '../types'

const initialState = {
    token: null,
    user: null,
    admin: null,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                admin: null
            }

        case LOGIN_ADMIN:
            return {
                ...state,
                token: action.payload.token,
                admin: action.payload.admin,
                user: null
            }

        case SIGN_OUT:
            return initialState

        default:
            return state
    }
}

export default authReducer