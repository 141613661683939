import { GET_ADMIN_USERS, SYSTEM_LOADING } from '../types'

const initialState = {
    adminUsers: [],
    isLoading: false
}

const systemReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ADMIN_USERS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false
            }

        case SYSTEM_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}

export default systemReducer