import { useEffect } from 'react';
import Table from '../../../common/table';
import { GET_SYSTEM_PRODUCT_DEALERS } from '../../../store/types'

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { deleteDoc } from '../../../store/actions/crud/delete';
import { get } from '../../../store/actions/crud/get'

// COMPONENTS
import AddDealer from './addDealer';

const Dealers = () => {
    const dispatch = useDispatch()
    const { dealers, isLoading } = useSelector(state => state.systemProducts)

    useEffect(() => {
        dispatch(get(
            {
                body: {
                    _model: "Dealer"
                },
                dispatchDetail: (response) => ({
                    type: GET_SYSTEM_PRODUCT_DEALERS,
                    payload: { dealers: response.data.doc }
                })
            }
        ))
    }, [])

    if (isLoading) {
        return <p>Loading...</p>
    }

    const deleteFunction = (obj) => {
        dispatch(deleteDoc(
            {
                body: {
                    _model: "Dealer",
                    params: {
                        _id: obj._id
                    },
                },

                callback: () => dispatch(get(
                    {
                        body: {
                            _model: "Dealer"
                        },

                        dispatchDetail: (response) => ({
                            type: GET_SYSTEM_PRODUCT_DEALERS,
                            payload: { dealers: response.data.doc }
                        }),
                    }

                )),




            }
        ))
    }

    return (
        <div className='p-4 text-white'>
            <div className='p-4 bg-slate-700 flex-col justify-center text-center'>
                <Table title={"Dealers"} data={dealers} addDelete={true} deleteFunction={deleteFunction} />
                <AddDealer />
            </div>
        </div>
    )
}

export default Dealers
