import Unauthorized from './unauthorized'
import Authorized from './authorized'

import { useSelector } from 'react-redux'
const NavBar = () => {
    const { user, admin } = useSelector(state => state.auth)

    return (
        user || admin ? <Authorized /> : <Unauthorized />
    )
}

export default NavBar