const Checkbox = ({ setSelectedAnswer, selectedAnswer, item }) => {

    let textStyle = {
        color: '#222',
        marginLeft: '16px',
        fontSize: '1.15rem'
    }

    let checkBoxStyle = {
        color: 'red',
        border: '1px red solid'
    }

    return (
        <label style={{
            // border: '1px red solid',
            margin: '4px 0px'
        }}>

            <input checked={(selectedAnswer && selectedAnswer.includes(item))} style={checkBoxStyle} value={item} type="checkbox" onChange={(e) => {

                console.log(e.target.checked, e.target.value)

                if (e.target.checked) {
                    if (selectedAnswer && selectedAnswer.length > 0) {
                        setSelectedAnswer([...selectedAnswer, item])
                    } else {
                        setSelectedAnswer([item])
                    }
                }

                if (!e.target.checked) {
                    for (let i = 0; i < selectedAnswer.length; i++) {
                        if (selectedAnswer[i] === e.target.value) {
                            selectedAnswer.splice(i, 1)
                            setSelectedAnswer(selectedAnswer)
                        }
                    }
                }

            }} />

            <span style={textStyle}>{item}</span>
        </label>
    )
}

export default Checkbox