import { GET_NOTIFICATIONS } from "../../types"
import { getPools } from "./get"

import axios from 'axios'

export const addPool = (shape, totalGallons, userId) => async (dispatch) => {
    console.log(userId)
    const body = {
        _model: "Pool",
        params: {
            userId: userId,
            shape: shape,
            totalGallons: totalGallons
        },
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/create`, body).catch(
        err => {
            dispatch({
                type: GET_NOTIFICATIONS,
                payload: { msg: err.response.data.msg, status: err.response.status, type: "error" }
            })
        }
    )

    if (!response) {
        return console.log("Error: Something went wrong with store/actions/auth.js")
    }

    console.log(response)

    dispatch(getPools(userId))
    console.log("got pools")


    // dispatch({
    //     type: ADD_POOLS,
    //     payload: { pools: response.data.doc }
    // })
}